import React, { useState } from 'react'
import about from '../../images/about.jpg'
import co from '../../images/co.jpg'
import { signInWithEmailAndPassword} from "firebase/auth"

import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../firebase_con'
function Login() {
  const [data, setdata] = useState({

    email:'',
    password:''
  });
  const [err, seterr] = useState(null);
 

  const navigate=useNavigate()
  
  const login=(e)=>{
    e.preventDefault()
  
   signInWithEmailAndPassword(auth,data.email,data.password)
  .then(message=>{
    if(message.email!==null)
    navigate('/dashboard')
  })
    .catch(err=>seterr(err.message))
 
  }
  return (
    <div>
    <div className="nav-bar1" style={{backgroundColor:' #0A1025'}}>
    <div className="container-fluid">
        <nav className="navbar1 navbar-expand-lg bg-dark navbar-dark">
            <Link to={'/'} className=""><img src={co} alt="" className="rounded-circle " style={{width:'50px'}}  /></Link>
    
   
           
               
           
        </nav>
    </div>
    </div>
   



  <div className="container py-5 my-5 ">
    <div className="row g-0 align-items-center mb-5">
    <div className="col-lg-6 mb-5 mb-lg-0 d-flex justify-content-center ">
      <img src={co} className="w-75 rounded-4 shadow-4" alt=''/>
      
      </div>
      <div className="col-lg-6 mb-5 mb-lg-0">
        <div className="card cascading-right" style={{    background: 'hsla(0, 0%, 100%, 0.55)',
          backdropFilter: 'blur(30px)'}}
        
            >
          <div className="card-body p-lg-5  shadow-5 text-center mb-5">
            <h2 className="fw-bold ">Sign in now</h2>
            <form onSubmit={login} className="pt-3">
            {err &&<div className="alert alert-primary" role="alert">
             <strong>{err}</strong>
            </div>}
               <div className="form-group mb-3">
                 <input type="email" className="form-control "  onChange={e => setdata({ ...data, email: e.target.value })}
                   placeholder="Enter Email" required/>
               </div>
               <div className="form-group">
                 <input type="password" className="form-control "  onChange={e => setdata({ ...data, password: e.target.value })}
                   placeholder="Enter Password" required/>
               </div>
               <div className="mt-3">
                 <button type='submit' className="btn btn-block w-100 btn-primary btn-lg font-weight-medium auth-form-btn"
                  >SIGN IN</button>
               </div>

             </form>
          </div>
        </div>
      </div>

      
    </div>
  </div>
    
    </div>
  )
}

export default Login