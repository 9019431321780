import { initializeApp } from "firebase/app";
import {getAuth} from 'firebase/auth'
const firebaseConfig = {

  apiKey: "AIzaSyAzECbQ7zzKroL6p4AacS1igI04U-nLIPI",

  authDomain: "fortino-4868a.firebaseapp.com",

  projectId: "fortino-4868a",

  storageBucket: "fortino-4868a.appspot.com",

  messagingSenderId: "113983411493",

  appId: "1:113983411493:web:a7cb2fbc8a0e5d37e0f6cc",

  measurementId: "G-CGNMVSY8JY"

};

  const app = initializeApp(firebaseConfig);
export const auth=getAuth(app)