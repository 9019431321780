import React, { useEffect, useState } from 'react'



import { Link } from 'react-router-dom'
import axios from 'axios';
function Homeblog() {
  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://fortback.tazimaplc.com.et/blogfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 3))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);
  

  return (
    <div>
    
    {data.length>0 && <section id="recent-blog-posts" class="recent-blog-posts">

      <div class="container" data-aos="fade-up">

        <header class="section-header">
         
          <div class=" d-flex justify-content-center "><p>Recent Blogs</p></div>
          
        </header>

        <div class="row d-flex justify-content-center ">

        {data.map((blog,i)=>{
          return   <div class="col-lg-4" key={i}>
            <div className="post-box">
            <div className="post-img"><img src={'https://fortback.tazimaplc.com.et/images/' + blog.image} class="img-fluid" alt=""/></div>
              <span class="post-date">{blog.date}</span>
              <h6 class="">
              <Link class="text-decoration-none "><b>{blog.subject}</b></Link>
            </h6>
              <div class="read-more d-flex justify-content-end ">
              <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none ">Read More<i class="bi bi-arrow-right"></i></Link>
            </div>
            </div>
          </div>
        }) }
      

        </div>
        
      </div>
      
    </section>}
    </div>
  )
}

export default Homeblog