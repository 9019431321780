import React, { useState } from 'react'


import axios from 'axios'

function Contact() {
  const [data, setdata] = useState({

    name:"",
    email:'',
    phone:'',
    subject:'',
    message:''
});
const [resp, setresp] = useState(null);

const handlecontact=(e)=>{
    e.preventDefault()
   
    axios.post('https://fortback.tazimaplc.com.et/contact', {name:data.name,phone:data.phone,email:data.email,subject:data.subject,message:data.message})
      .then(res => {
        if (res.data.status==="success") {
        setresp(res.data.status)
        setdata({name:"",phone:"",email:"",subject:"",message:""})
        }
      })
      .catch(err => console.log(err))


}


  return (
    <div class="">
    <section class="breadcrumbs">
    <div class="container">

      
      <h2 class="mt-3">Contact Us</h2>

    </div>
  </section>
    <section id="contact" class="contact">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
      
        <h1>Keep In Touch</h1>
      </header>

      <div class="row ">

        <div class="col-lg-6">

          <div class="row gy-2">
            <div class="col-md-6  ">
              <div class="info-box text-center">
                <i class="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <h6>Addis Abeba,Ethiopia</h6>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box text-center">
                <i class="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <h6>+251 979235206<br/>+251 960396195</h6>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box text-center">
                <i class="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <h6>fortinotech@gmail.com</h6>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box text-center">
                <i class="bi bi-clock"></i>
                <h3>Open Hours</h3>
                <h6>24/7</h6>
              </div>
            </div>
          </div>

        </div>

        <div class="col-lg-6">


          <form onSubmit={handlecontact}  class="php-email-form">
            <div class="row gy-4">
            { resp && <div className="alert alert-success" role="alert">
            <strong>Your message is delivered!  Thanks for contacting us  </strong>
        </div>}
              <div class="col-md-6">
                <input type="text"  class="form-control" placeholder="Your Name" required
                value={data.name} onChange={e => setdata({ ...data, name: e.target.value })}
                />
              </div>

              <div class="col-md-6 ">
                <input type="email" class="form-control"  placeholder="Your Email" required
                value={data.email} onChange={e => setdata({ ...data, email: e.target.value })}
                />
              </div>
              <div class="col-md-12">
              <input type="number" class="form-control"  placeholder="Your Phone Number" required
              value={data.phone} onChange={e => setdata({ ...data, phone: e.target.value })}
              />
            </div>
              <div class="col-md-12">
                <input type="text" class="form-control"  placeholder="Subject" required
                value={data.subject} onChange={e => setdata({ ...data, subject: e.target.value })}
                />
              </div>

              <div class="col-md-12">
                <textarea class="form-control" name="message" rows="3" placeholder="Message" required
                value={data.message} onChange={e => setdata({ ...data, message: e.target.value })}
                ></textarea>
              </div>

              <div class="col-md-12 text-center">
                
                
                

                <button type="submit">Send Message</button>
              </div>

            </div>
          </form>

        </div>

      </div>

    </div>

  </section>
    
    
    </div>
  )
}

export default Contact