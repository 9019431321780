import React, { useEffect, useState } from 'react'

import axios from 'axios';
import { Link } from 'react-router-dom';
function Portfolio() {

    const [hover, sethover] = useState("All");

    const [data, setdata] = useState([]);

 
    useEffect(() => {
        axios.get("https://fortback.tazimaplc.com.et/portfoliofetch")
        .then(res=>{
    if (res.data.status==="success") {
        setdata(res.data.Result.sort((a, b) => b.id - a.id))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);


    const handlehover=(status)=>{
        sethover(status)
        
        
        
            }
  
  return (
    <div>
    <section class="breadcrumbs">
    <div class="container">

      
      <h2 class="">Portfolio</h2>

    </div>
  </section>
  <section id="portfolio" class="portfolio">

  <div class="container" data-aos="fade-up">
  
    <header class="section-header">
      <h2>Portfolio</h2>
    
      <div class=" d-flex justify-content-center "><p>Check our  work</p></div>
    </header>
  
    <div class="row" data-aos="fade-up" data-aos-delay="100">
      <div class="col-lg-12 d-flex justify-content-center">
        <ul id="portfolio-flters">
          <li data-filter="*" onClick={()=>handlehover('All')} className={`${hover==="All" && "filter-active"}`}>All</li>
          <li data-filter=".filter-app" onClick={()=>handlehover('App')} className={`${hover==="App" && "filter-active"}`}>App</li>
          <li data-filter=".filter-card" onClick={()=>handlehover('Card')} className={`${hover==="Card" && "filter-active"}`}>Card</li>
          <li data-filter=".filter-web" onClick={()=>handlehover('Web')} className={`${hover==="Web" && "filter-active"}`}>Web</li>
        </ul>
      </div>
    </div>
  
    <div class="row gy-4 portfolio-container" data-aos="fade-up" data-aos-delay="200">
  
     {data.filter((porti) => porti.type === hover).map((ports,i)=>{
        return <div class="col-lg-4 col-md-6 portfolio-item filter-app" key={i}>
        <div class="portfolio-wrap">
          <img src={'https://fortback.tazimaplc.com.et/images/' + ports.image} class="img-fluid rounded" alt=""/>
          <div class="portfolio-info">
            <h4>{ports.name}</h4>
            <p>{ports.type}</p>
            <div class="portfolio-links">
             
              <Link to={ports.link} title="More Details" target="_blank"><i class="bi bi-link"></i></Link>
            </div>
          </div>
        </div>
      </div>
     })}{hover==="All"&&data.map((ports,i)=>{
        return <div class="col-lg-4 col-md-6 portfolio-item filter-app" key={i}>
        <div class="portfolio-wrap">
          <img src={'https://fortback.tazimaplc.com.et/images/' + ports.image} class="img-fluid rounded" alt=""/>
          <div class="portfolio-info">
            <h4>{ports.name}</h4>
            <p>{ports.type}</p>
            <div class="portfolio-links">
             
              <Link to={ports.link} title="More Details" target="_blank"><i class="bi bi-link"></i></Link>
            </div>
          </div>
        </div>
      </div>
     })}

  
      
  
     
  
    </div>
  
  </div>
  
  </section>
    
    
    </div>
  )
}

export default Portfolio