
import './App.css';

import { Route, Routes } from 'react-router-dom';
import Home from './Components/Home';
import Header from './Components/Header';
import About from './Components/About';
import Blog from './Components/Blog';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Dashboard from './Components/Admin/Dashbord';
import AdminBlog from './Components/Admin/AdminBlog';
import CreateBlog from './Components/Admin/CreateBlog';
import UpdateBlog from './Components/Admin/UpdateBlog';
import Viewmessage from './Components/Admin/Viewmessage';
import TeamManagement from './Components/Admin/Team_management';
import Login from './Components/Admin/Login';
import BlogDetail from './Components/BlogDetail';
import Portfolio from './Components/Portfolio';
import Adminportfolio from './Components/Admin/Adminportfolio';
import { useEffect, useState } from 'react';
import { auth } from './firebase_con';

function App() {

  const [user, setuser] = useState(null);
  useEffect(() => {
    auth.onAuthStateChanged((authUser)=>{
      if(authUser){
       
      setuser(authUser)

      }})
    }, []);
   console.log(user);

  return (
   <Routes>
   <Route path='/' element={<div><Header/><Home/><Footer/></div>}/>
   <Route path='/about' element={<div><Header/><About/><Footer/></div>}/>
   <Route path='/blog' element={<div><Header/><Blog/><Footer/></div>}/>

   <Route path='/portfolio' element={<div><Header/><Portfolio/><Footer/></div>}/>
   <Route path='/contact' element={<div><Header/><Contact/><Footer/></div>}/>
   <Route path='/blogdetail/:id' element={<div><Header/><BlogDetail/><Footer/></div>}/>
   
{user &&<Route path='/dashboard' element={<div><Dashboard/></div>}/>}
{user &&<Route path='/adminblog' element={<div><AdminBlog/></div>}/>}
 {user &&<Route path='/createblog' element={<div><CreateBlog/></div>}/>}
 {user &&<Route path='/updateblog/:id' element={<div><UpdateBlog/></div>}/>}
  {user &&<Route path='/viewmessage' element={<div><Viewmessage/></div>}/>}
  {user &&<Route path='/teamcontrol' element={<div><TeamManagement/></div>}/>}
  {user &&<Route path='/adminportfolio' element={<div><Adminportfolio/></div>}/>}
    <Route path='/adminlogin' element={<div><Login/></div>}/>

   
   </Routes>
  );
}

export default App;
