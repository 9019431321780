import React from 'react'
import {Link} from 'react-router-dom'
import  about from '../images/co.jpg'
import {Link as scrollto} from 'react-scroll'
function Header() {
  return (
    <div>
    
    <div className="nav-bar" style={{backgroundColor:'white'}}>
<div className="container-fluid">
    <nav className="navbar navbar-expand-lg bg-dark navbar-dark">
    <Link to={'/'} class="loo bolded">
              
   <strong><h2><b>Fortino Tech</b></h2></strong> 
  </Link>
        <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
            <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
   

           
            <div className="navbar-nav mr-auto nab">
                <Link to={'/'} className="nav-item nav-link " data-toggle="collapse" data-target="#navbarCollapse">Home</Link>
                <Link to={'/about'} className="nav-item nav-link " data-toggle="collapse" data-target="#navbarCollapse" >ABOUT</Link>
                <Link to={'/portfolio'}  className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">PORTFOLIO</Link>
                <Link to={'/'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">SERVICES</Link>
                <Link to={'/about'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">TEAM</Link>
               
              
                <Link to={'/blog'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">BLOG</Link>
                <Link to={'/contact'} className="nav-item nav-link" data-toggle="collapse" data-target="#navbarCollapse">CONTACT</Link>
            </div>
            
            <div className="ml-auto">
            <Link to={'/contact'} className="btn" >Contact us</Link>
            </div>
        </div>
    </nav>
</div>
</div>
    
    
    </div>
  )
}

export default Header