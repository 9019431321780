import React from 'react'
import  hero from '../images/hero-img.png'
import  about from '../images/co.jpg'
import  values3 from '../images/values-3.png'
import  values1 from '../images/values-1.png'
import  values2 from '../images/values-2.png'
import  features3 from '../images/features-3.png'
import  features2 from '../images/features-2.png'
import  graphics from '../images/graphics.gif'
import  consult from '../images/consult.gif'


import Team from './Team'
import Homeblog from './Homeblog'
import { Link } from 'react-router-dom'
import Homeportfolio from './Homeportfolio'


function Home() {
  return (
    <div>
    <section id="hero" class="hero d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">We offer modern solutions to grow your business</h1>
         
         
        </div>
        <div class="col-lg-6 hero-img" data-aos="zoom-out" data-aos-delay="200">
          <img src={hero} class="img-fluid" alt=""/>
        </div>
      </div>
    </div>

  </section>
    


  <section id="about" class="about">

  <div class="container" data-aos="fade-up">
    <div class="row gx-0">
    <div class="col-lg-6 d-flex align-items-center" data-aos="zoom-out" data-aos-delay="200">
    <img src={about} class="img-fluid rounded" alt=""/>
  </div>
      <div class="col-lg-6 d-flex flex-column justify-content-center " data-aos="fade-up" data-aos-delay="200">
        <div class="content  bg-white">
          <h3>Who We Are</h3>
         <h2 className="mb-2">About Fortino Tech And It's Innovative IT Solutions</h2>
          <p className='tex text-justify'> Welcome to Fortino Tech, a forward-thinking software development company committed to delivering state-of-the-art solutions for both businesses and individuals. Our expertise spans website, application, and API development, as well as cutting-edge machine learning and AI. At Fortino Tech, we firmly believe that technology plays a pivotal role in advancing all facets of society. Our mission is to contribute to Ethiopia and Africa’s digital transformation by harnessing our skills and knowledge</p>
          <div class="text-center text-lg-start">
            <Link to={'/about'} class="btn-read-more d-inline-flex align-items-center justify-content-center align-self-center">
              <span>Read More</span>
              <i class="bi bi-arrow-right"></i>
            </Link>
          </div>
        </div>
      </div>

     

    </div>
  </div>

</section>



<section id="services" class="values ">

<div class="container " data-aos="fade-up">

  <header class="section-header">
    <h3 className='text-primary'>Our Services</h3>
    
  </header>

  <div class="row">

    <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
      <div class="box">
      <img src={features2} class="img-fluid pb-5" alt=""/>
     
        <h3>Website Development</h3>
      
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
      <div class="box">
        <img src={values2} class="img-fluid" alt=""/>
        <h3>Custom API Development</h3>
       
     
      </div>
    </div>

    <div class="col-lg-4 mt-4 mt-lg-0 " data-aos="fade-up" data-aos-delay="600">
      <div class="box ">
        <img src={values3} class="img-fluid" alt=""/>
        <h3>Mobile App Development</h3>
      
      </div>
    </div>

  </div>
  <div class="row mt-5">

  <div class="col-lg-4" data-aos="fade-up" data-aos-delay="200">
    <div class="box">
    <img src={graphics} class="img-fluid pb-5" alt=""/>
      <h3>Graphics Design</h3>
    </div>
  </div>

  <div class="col-lg-4 mt-4 mt-lg-0" data-aos="fade-up" data-aos-delay="400">
    <div class="box">
      <img src={consult} class="img-fluid pb-5" alt=""/>
      <h3>IT Consultancy </h3>
     
   
    </div>
  </div>

  <div class="col-lg-4 mt-4 mt-lg-0 " data-aos="fade-up" data-aos-delay="600">
    <div class="box ">
    <img src={values1} class="img-fluid" alt=""/>
      <h3>Digital Marketing</h3>
    
    </div>
  </div>

</div>

</div>

</section>

    





<section  class="features">

<div class="row feature-icons" data-aos="fade-up">
<h3 class="mb-5 px-3">Our Values</h3>

<div class="row ">

  <div class="col-xl-4 text-center" data-aos="fade-right" data-aos-delay="100">
    <img src={features3} class="img-fluid p-4" alt=""/>
  </div>

  <div class="col-xl-8 d-flex content">
    <div class="row align-self-center gy-4">

      <div class="col-md-6 icon-box" data-aos="fade-up">
        <i class="ri-line-chart-line"></i>
        <div>
          <h4 class="text-center">Understanding Client Requirements</h4>
          <p> We initiate by engaging in in-depth discussions with our clients. This helps us comprehend their business needs, pain points, and aspirations. Whether they seek to solve a problem or seize an opportunity, we’re all ears.</p>
        </div>
      </div>

      <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="100">
        <i class="ri-stack-line"></i>
        <div>
          <h4 class="text-center">Design and Architecture</h4>
          <p>The magic unfolds here! We create the blueprint for custom software, covering system architecture, database design, user interface, and other technical specifications. Leveraging industry best practices and design patterns ensures a robust and scalable solution.</p>
        </div>
      </div>

      <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="200">
        <i class="ri-brush-4-line"></i>
        <div>
          <h4 class="text-center">Planning and Analysis</h4>
          <p>Once we’ve gathered the requirements, we dive into meticulous planning and analysis. This involves breaking down the project into smaller components, estimating the scope of work, setting timelines, and scrutinizing technical aspects.</p>
        </div>
      </div>

      <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="300">
        <i class="ri-magic-line"></i>
        <div>
          <h4 class="text-center">Development and Coding</h4>
          <p>It’s time for the coding frenzy! Our talented developers roll up their sleeves and breathe life into the designs. We follow agile methodologies for flexibility and high-quality output, always emphasizing clean, maintainable, and well-documented code.</p>
        </div>
      </div>

      <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="400">
        <i class="ri-command-line"></i>
        <div>
          <h4 class="text-center">Embracing Collaboration</h4>
          <p>You’re not a mere observer in the development of your software solution. Far from it! We thrive on working hand in hand with you throughout the entire journey. Our approach isn’t the typical “here’s the finished project, take it or leave it.” Instead, we’re more like a “let’s tackle this together” team. You’re an integral part of the process, and consequently, the outcome!</p>
        </div>
      </div>

      <div class="col-md-6 icon-box" data-aos="fade-up" data-aos-delay="500">
        <i class="ri-radar-line"></i>
        <div>
          <h4 class="text-center">Continual Support</h4>
          <p>Beyond development, we provide ongoing support and maintenance to ensure your software operates at peak performance. This encompasses bug fixes, updates, and even scaling the software as your business expands. We’re here for the long haul!</p>
        </div>
      </div>

    </div>
  </div>

</div>

</div>


</section>






<Homeportfolio/>


<Homeblog/>

    </div>
  )
}

export default Home