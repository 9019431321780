import React, { useEffect, useState } from 'react'
import  about from '../images/co.jpg'
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
function BlogDetail() {
    const [data, setdata] = useState([]);
    const [latest, setlatest] = useState([]);
    const { id } = useParams();

    useEffect(() => {
        axios.get('https://fortback.tazimaplc.com.et/detail/' + id)
          .then(res => {
            if (res.data.status === "success") {
              setdata(res.data.Result)
             
            }
          })
          .catch(err => console.log(err))
      }, [id]);



      useEffect(() => {
        axios.get("https://fortback.tazimaplc.com.et/blogfetch")
        .then(res=>{
    if (res.data.status==="success") {
      
        setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 4))
        
    }
            
        })
        .catch(err=>console.log(err))
       
    }, []);
  return (
    <div>
    <section class="breadcrumbs">
    <div class="container">

      
      <h2 class="mt-3">Blog Detail</h2>

    </div>
  </section>
  <section id="blog" class="blog">
  <div class="container" data-aos="fade-up">

    <div class="row">

      <div class="col-lg-8 entries">

      {data.map((detail,i)=>{
		return	  <article class="entry entry-single" key={i}>

          <div class="entry-img">
            <img src={'https://fortback.tazimaplc.com.et/images/' + detail.image} alt="" class="img-fluid"/>
          </div>

          <h6 class="">
              <Link class="text-decoration-none "><b>{detail.subject}</b></Link>
            </h6>

          <div class="entry-meta">
            <ul>
              
              <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <Link className='dec text-decoration-none '><time datetime="2020-01-01">{detail.date}</time></Link></li>
              <li class="d-flex align-items-center"><i class="bi bi-eye"></i> <Link className='dec text-decoration-none '>{detail.view}</Link></li>
            </ul>
          </div>

          <div class="entry-content">
            <p>
             {detail.detail}
            </p>

           

          {detail.special &&  <blockquote>
              <p>
               {detail.special}
              </p>
            </blockquote>}

         {detail.detail2 &&   <p>
              {detail.detail2}
            </p>}


         

          </div>

          <div class="entry-footer">
            <i class="bi bi-folder"></i>
            <ul class="cats">
              <li><Link className='dec text-decoration-none '>Web development</Link></li>
            </ul>

            <i class="bi bi-tags"></i>
            <ul class="tags">
              <li><Link className='dec text-decoration-none '>Creative</Link></li>
              <li><Link className='dec text-decoration-none '>Tips</Link></li>
              <li><Link className='dec text-decoration-none '>AI</Link></li>
            </ul>
          </div>

        </article>

    })}

        

      </div>
      <div class="col-lg-4 mr-0 px-0">

      <div class="sidebar mx-0">

        


        <h3 class="sidebar-title">Recent Posts</h3>
        <div class="sidebar-item recent-posts">
        {latest.map((blog,i)=>{
          return   <div class="post-item clearfix" key={i}>
          <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
             <img src={'https://fortback.tazimaplc.com.et/images/' + blog.image} alt=""/>
             <h4>{blog.subject}</h4>
             <time datetime="2020-01-01">{blog.date}</time>
             </Link>
           </div>
        }) }
      
         

  

        <h3 class="sidebar-title">Tags</h3>
        <div class="sidebar-item tags">
        <ul>
        <li><Link class="text-decoration-none ">App</Link></li>
        <li><Link class="text-decoration-none ">IT</Link></li>
        <li><Link class="text-decoration-none ">Business</Link></li>

        <li><Link class="text-decoration-none ">Software</Link></li>
       
        <li><Link class="text-decoration-none ">Web Development</Link></li>
        <li><Link class="text-decoration-none ">App Development</Link></li>
        <li><Link class="text-decoration-none ">Tips</Link></li>
        <li><Link class="text-decoration-none ">Digital Marketing</Link></li>
      </ul>
        </div>

      </div>

    </div>

  </div>


    </div>

  </div>
</section>


    
    </div>
  )
}

export default BlogDetail