import React, { useEffect, useState } from 'react'
import  blog1 from '../images/blog/blog-1.jpg'
import  blog2 from '../images/blog/blog-2.jpg'
import  blog3 from '../images/blog/blog-3.jpg'
import  blog4 from '../images/blog/blog-4.jpg'
import  blogr1 from '../images/blog/blog-recent-1.jpg'
import  blogr2 from '../images/blog/blog-recent-2.jpg'
import  blogr3 from '../images/blog/blog-recent-3.jpg'
import  blogr4 from '../images/blog/blog-recent-4.jpg'
import  blogr5 from '../images/blog/blog-recent-5.jpg'
import { Link } from 'react-router-dom'
import axios from 'axios'
function Blog() {
  const [data, setdata] = useState([]);
  const [latest, setlatest] = useState([]);
  useEffect(() => {
      axios.get("https://fortback.tazimaplc.com.et/blogfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      setlatest(res.data.Result.sort((a, b) => b.id - a.id).slice(0, 4))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);

  const truncate = (str, len) => str.slice(0, len);


  return (
    <div >
    <section class="breadcrumbs">
    <div class="container">

      
      <h2 class="">Blogs</h2>

    </div>
  </section>

    <section id="blog" class="blog ">
    <div class="container" data-aos="fade-up">

      <div class="row">

        <div class="col-lg-8 entries">

           {data.map((blog,i)=>{
     return     <article class="entry" key={i}>

            <div class="entry-img">
              <img src={'https://fortback.tazimaplc.com.et/images/' + blog.image} alt="" class="img-fluid"/>
            </div>

            <h6 class="">
              <Link class="text-decoration-none "><b>{blog.subject}</b></Link>
            </h6>

            <div class="entry-meta">
              <ul>
               
                <li class="d-flex align-items-center"><i class="bi bi-clock"></i> <Link class="text-decoration-none "><time datetime="2020-01-01">{blog.date}</time></Link></li>
                <li class="d-flex align-items-center"><i class="bi bi-eye"></i> <Link class="text-decoration-none ">{blog.view}</Link></li>
              </ul>
            </div>

            <div class="entry-content">
              <p>
                {blog.detail}
              </p>
              <div class="read-more">
                <Link to={`/blogdetail/${blog.id}`} class="text-decoration-none ">Read More</Link>
              </div>
            </div>

          </article>
        }) }
         

      

        </div>

        <div class="col-lg-4 mr-0 px-0">

          <div class="sidebar mx-0">

            


            <h3 class="sidebar-title">Recent Posts</h3>
            <div class="sidebar-item recent-posts">
            {latest.map((blog,i)=>{
              return   <div class="post-item clearfix" key={i}>
             <Link to={`/blogdetail/${blog.id}`} className='text-decoration-none '>
                <img src={'https://fortback.tazimaplc.com.et/images/' + blog.image} alt=""/>
                <h4>{blog.subject}</h4>
                <time datetime="2020-01-01">{blog.date}</time>
                </Link>
              </div>
            }) }
          
             

      

            <h3 class="sidebar-title">Tags</h3>
            <div class="sidebar-item tags">
              <ul>
                <li><Link class="text-decoration-none ">App</Link></li>
                <li><Link class="text-decoration-none ">IT</Link></li>
                <li><Link class="text-decoration-none ">Business</Link></li>

                <li><Link class="text-decoration-none ">Software</Link></li>
               
                <li><Link class="text-decoration-none ">Web Development</Link></li>
                <li><Link class="text-decoration-none ">App Development</Link></li>
                <li><Link class="text-decoration-none ">Tips</Link></li>
                <li><Link class="text-decoration-none ">Digital Marketing</Link></li>
              </ul>
            </div>

          </div>

        </div>

      </div>

    </div>
    </div>
  </section>
   
    
    </div>
  )
}

export default Blog