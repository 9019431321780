import React, { useEffect, useState } from 'react'

import axios from 'axios';

function Team() {

  const [data, setdata] = useState([]);
  useEffect(() => {
      axios.get("https://fortback.tazimaplc.com.et/teamfetch")
      .then(res=>{
  if (res.data.status==="success") {
      setdata(res.data.Result.sort((a, b) => b.id - a.id))
      
  }
          
      })
      .catch(err=>console.log(err))
     
  }, []);


  return (
    <div>
  {data.length>0 &&   <section id="team" class="team">

    <div class="container" data-aos="fade-up">

      <header class="section-header">
        <h2>Team</h2>
        <p className='d-flex justify-content-center '>Our hard working team</p>
      </header>

      <div class="row gy-4">

       
        {data.map((members,i)=>{
          return  <div class="col-lg-3 col-md-6 d-flex align-items-stretch" data-aos="fade-up" data-aos-delay="100" key={i}>
          
          <div class="member" >
            <div class="member-img">
              <img src={'https://fortback.tazimaplc.com.et/images/' + members.Image} class="img-fluid" alt=""/>
              <div class="social">
                {members.Tweeter && <a href={members.Tweeter}><i class="bi bi-twitter text-primary "></i></a>}
                {members.Facebook && <a href={members.Facebook}><i class="bi bi-facebook text-primary"></i></a>}
                {members.Instagram && <a href={members.Instagram}><i class="bi bi-instagram text-primary"></i></a>}
               { members.Linkedin && <a href={members.Linkedin}><i class="bi bi-linkedin text-primary"></i></a>}
              </div>
            </div>
            <div class="member-info">
              <h4>{members.Name}</h4>
              <span>{members.Position}</span>
             
            </div>
          </div>
          </div>
        }) }

      


      </div>

    </div>

  </section>}
    
    
    </div>
  )
}

export default Team