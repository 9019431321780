import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  return (
    <div>
    
    <footer id="footer" class="footer">

   

    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info">
            <Link href="index.html" class="logo d-flex align-items-center">
              
              <span>Fortino Tech</span>
            </Link>
            <p>We help people to build incredible brands and superior products. Our perspective is to furnish outstanding captivating services.</p>
            <div class="social-links mt-3">
              <Link  class="twitter text-decoration-none"><i class="bi bi-twitter"></i></Link>
              <Link to={'https://www.facebook.com/profile.php?id=61558142331039'} class="facebook text-decoration-none"><i class="bi bi-facebook"></i></Link>
              <Link  class="instagram text-decoration-none"><i class="bi bi-instagram"></i></Link>
              <Link  class="linkedin text-decoration-none"><i class="bi bi-linkedin"></i></Link>
            </div>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <Link to={'/'}  class="text-decoration-none ">Home</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link to={'/about'} class="text-decoration-none ">About us</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link to={'/blog'} class="text-decoration-none ">Blog</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link to={'/contact'} class="text-decoration-none ">Contact Us</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">Privacy policy</Link></li>
            </ul>
          </div>

          <div class="col-lg-2 col-6 footer-links">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">API Development</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">Web Development</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">App Development </Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">IT Consultancy </Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">Graphic Design</Link></li>
              <li><i class="bi bi-chevron-right"></i> <Link class="text-decoration-none ">Digital Marketing</Link></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-12 footer-contact text-center text-md-start">
            <h4>Contact Us</h4>
            <h6>
              Addis Abeba ,
              
              Ethiopia<br/>
              <strong>Phone:</strong> +251979235206<br/>
              <strong>Email:</strong> fortinotech@gmail.com<br/>
            </h6>

          </div>

        </div>
      </div>
    </div>

    <div class="container">
      <div class="copyright">
        &copy; Copyright <strong><span>Fortino Tech</span></strong>. All Rights Reserved
      </div>
    
    </div>
  </footer>
    
    </div>
  )
}

export default Footer